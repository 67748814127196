html {
  height: 100%;
  background-color: rgb(203, 0, 118, 1);
}
body {
  height: 100%;
  background-color: white;
}
div.App {
  height: 100%;
  text-align: center;
}
div#root {
  height: 100%;
}
